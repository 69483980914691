<template>
    <div v-if="userData && roles.length > 0">
        <company-user-edit-tab-account :user-data="userData" :read-only="readOnly" :roles="roles" class="mt-2 pt-75" />
    </div>
</template>

<script>
import router from '@/router'
import userManagementService from '@/http/requests/system/userManagementService'
import userRoleService from '@/http/requests/system/userRoleService'
import CompanyUserEditTabAccount from './CompanyUserEditTabAccount.vue'

export default {
  components: {
    CompanyUserEditTabAccount,
  },
  data: () => ({
    userData: null,
    roles: [],
    readOnly: false,
  }),
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getUserData()
    },
    getUserData() {
      const userId = router.currentRoute.params.userId
      // const readOnly = router.currentRoute.params.readOnly
      const companyId = router.currentRoute.params.companyId
      const companyType = router.currentRoute.params.companyType
      userRoleService.getUserSubRoles(companyId).then(resultRoles => {
        const subRoles = resultRoles.data
        this.roles.push(...subRoles)
      }).then(() => {
        if (userId) {
          userManagementService.fetchCompanyUser(companyId, userId).then(resultUser => {
            this.userData = resultUser
          })
        } else {
          this.userData = { companyType: companyType }
        }
      })
    },
  },
}
</script>
