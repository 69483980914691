<template>
  <div class="container-fluid">
    <!-- User Info: Input Fields -->
    <b-card>
      <b-form>
        <div class="row">
          <!-- Field: FirstName -->
          <div class="col-12 col-md-4">
            <b-form-group label="Firstname" label-for="firstname">
              <b-form-input
                id="firstName"
                v-model="userModel.firstName"
                :readonly="readOnly"
              />
            </b-form-group>
          </div>
          <!-- Field: Last Name -->
          <div class="col-12 col-md-4">
            <b-form-group label="Middle Name" label-for="middle-name">
              <b-form-input
                id="middle-name"
                v-model="userModel.middleName"
                :readonly="readOnly"
              />
            </b-form-group>
          </div>
          <!-- Field: Email -->
          <div class="col-12 col-md-4">
            <b-form-group label="Last Name" label-for="last name">
              <b-form-input
                id="lastName"
                v-model="userModel.lastName"
                :readonly="readOnly"
              />
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <!-- Field: Username -->
          <div class="col-12 col-md-4">
            <b-form-group label="Username" label-for="username">
              <b-form-input
                id="username"
                v-model="userModel.username"
                :readonly="readOnly"
              />
            </b-form-group>
          </div>
          <!-- Field: Email -->
          <div class="col-12 col-md-4">
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="userModel.email"
                :readonly="readOnly"
                type="email"
              />
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <!-- Field: Warehouse -->
          <div v-if="companyType && companyType==='warehouse' && hasPermission" class="col-12 col-md-4">
            <b-form-group label="Active Warehouse" label-for="active-warehouses">
              <b-form-select
                id="activewarehouse"
                v-model="selectedWarehouse"
                :options="warehouses"
                class="mr-1"
              />
            </b-form-group>
          </div>
          <!-- Field: Role -->
          <div class="col-12 col-md-4">
            <b-form-group label="User Role" label-for="user-role">
              <b-form-select
                id="userrole"
                v-model="selectedRole"
                :options="userRoles"
                class="mr-1"
              />
            </b-form-group>
          </div>
          <!-- Field: Status -->
          <div class="col-12 col-md-4">
            <b-form-group label="Status" label-for="user-status">
              <b-form-select
                id="status"
                v-model="selectedStatus"
                :options="statusOptions"
                class="mr-1"
              />
            </b-form-group>
          </div>
        </div>
      </b-form>
      <!-- Action Buttons -->
      <b-button
        v-show="!readOnly"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        variant="primary"
        @click="saveCompanyUser"
      >
        Save Changes
      </b-button>
      <b-button variant="outline-secondary">
        Reset
      </b-button>
    </b-card>
  </div>
</template>

<script>
// import vSelect from 'vue-select'
import userService from '@/http/requests/system/userService'
import { Notify } from '@robustshell/utils/index'
import shipService from '@/http/requests/ship/shipService'
import find from 'lodash/find'
import { UserDTO } from '@/http/models/system/user-management/UserDTO'

export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      statusOptions: [
        { text: 'Select a status', value: 'none' },
        { text: 'Active', value: true },
        { text: 'Passive', value: false },
      ],
      companyType: null,
      selectedStatus: 'none',
      userModel: {
        login: null,
        firstName: null,
        middleName: null,
        lastName: null,
        email: null,
        role: { id: null, roleName: null },
      },
      userDTO: new UserDTO(),
      warehouses: [],
      selectedWarehouse: null,
      userRoles: [],
      selectedRole: 0,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  mounted() {
    this.userModel = this.userData
    this.companyType = this.userData.companyType
    this.userRoles.splice(0, this.userRoles.length)
    this.userRoles.push({ value: 0, text: 'Select Role' })
    if (this.roles) {
      this.roles.forEach(role => {
        this.userRoles.push({ value: role.id, text: role.roleName })
      })
    }
    this.selectedRole = (this.userModel.role !== undefined) ? this.userModel.role.id : 0
    this.selectedStatus = (this.userModel.status === 'active')
    this.getWarehouses()
  },
  methods: {
    async getWarehouses() {
      const result = await shipService.fetchWarehouses()
      const data = result.data
      this.warehouses.splice(0, this.warehouses.length)
      this.warehouses.push({ value: 0, text: 'Select Warehouse' })
      data.forEach(element => {
        if (element.name.toLowerCase() !== 'common') {
          this.warehouses.push({ value: element.id, text: element.name })
        }
      })
      this.selectedWarehouse = this.warehouses[0].value
      if (this.userData.warehouse && this.userData.warehouse.id) {
        this.selectedWarehouse = find(this.warehouses,
          {
            value: this.userData.warehouse.id,
          }).value
      }
    },
    saveCompanyUser() {
      this.userDTO = new UserDTO()
      this.userDTO.login = this.userData.username
      this.userDTO.firstName = this.userData.firstName
      this.userDTO.middleName = this.userData.middleName
      this.userDTO.lastName = this.userData.lastName
      this.userDTO.email = this.userData.email
      this.userDTO.activated = this.selectedStatus
      this.userDTO.warehouse = this.userData.warehouse
      if (this.selectedWarehouse != null && this.companyType === 'warehouse') {
        this.userDTO.warehouse = null
        if (this.selectedWarehouse !== 0) {
          this.userDTO.warehouse = { id: this.selectedWarehouse }
        }
      }
      if (this.selectedRole === 0 || this.selectedRole === '' || this.selectedRole === null || typeof this.selectedRole === 'undefined') {
        Notify.warning('Please select a role for this user.')
        return
      }
      if (!this.isRequired(this.userData.username)) {
        Notify.warning('Please enter username.')
        return
      }
      if (!this.isRequired(this.userData.email)) {
        Notify.warning('Please enter email address.')
        return
      }
      if (this.companyType === 'warehouse' && (this.selectedWarehouse === 0 || this.selectedWarehouse === '' || this.selectedWarehouse === null || typeof this.selectedWarehouse === 'undefined')) {
        Notify.warning('Please select warehouse.')
        return
      }
      if (this.selectedWarehouse != null && this.companyType === 'warehouse') {
        this.userDTO.warehouse = null
        if (this.selectedWarehouse !== 0) {
          this.userDTO.warehouse = { id: this.selectedWarehouse }
        }
      }
      this.userDTO.userRole = {
        user: { id: this.userData.id },
        role: { id: this.selectedRole },
      }
      if (this.userData.id) {
        this.userDTO.id = this.userData.id
        userService.updateUser(this.userDTO).then(() => {
          this.$router.push('/apps/account/company/0')
        })
      } else {
        userService.createNewUser(this.userDTO).then(() => {
          this.$router.push('/apps/account/company/0')
        })
      }
    },
    isRequired(value) {
      if (value !== '' && value !== null && typeof value !== 'undefined') {
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
