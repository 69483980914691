/* eslint-disable lines-between-class-members */
export class UserDTO {
   login= null
   firstName = null
   middleName = null
   lastName = null
   email = null
   userRole = null
   activated = null
   warehouse = null
}
